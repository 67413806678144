<template>
  <v-row
    class="align-center activity-bar"
    no-gutters
  >
    <v-col class="align-center">
      <v-row>
        <v-col
          md="4"
          class="bar-column d-flex align-center"
        >
          <activity-user
            :active-btn="activeBtn"
            :permissions="getFiltersData.btns"
            @set-active-button="setActiveButton"
          />
        </v-col>
        <v-col class="bar-column">
          <date-picker
            v-model="params.date"
          />
        </v-col>
      </v-row>
    </v-col>
    <v-col>
      <v-row
        class="justify-end"
      >
        <v-col class="bar-column d-flex justify-end" md="4">
          <member-filter v-model="params.member" :items="getFiltersData.members" />
        </v-col>
        <v-col class="bar-column d-flex justify-end" md="4">
          <activity-project v-model="params.project" :items="getFiltersData.projects" />
        </v-col>
      </v-row>
    </v-col>
    <v-col v-if="deleted" class="deleted" md="12">
      <div class="deleted-date">
        <span class="font-weight-bold">
          {{ $moment(pickedDate).format('DD MMM, dddd') }}
        </span>
      </div>
      <div class="deleted-total">
        Total deleted:
        <span class="font-weight-bold">
          05:45:40
        </span>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import ActivityUser from '@/components/collections/FiltersBar/UserFilter';
import DatePicker from '@/components/collections/DatePicker/DatePicker';
import ActivityProject from '@/components/collections/FiltersBar/ProjectFilter';
import MemberFilter from '@/components/collections/FiltersBar/MemberFilter';
import { mapGetters } from 'vuex';
import queryParamsMixin from '@/mixins/queryParamsMixin';

export default {
  name: 'FiltersBar',
  components: {
    MemberFilter,
    ActivityProject,
    DatePicker,
    ActivityUser,
  },
  mixins: [queryParamsMixin],
  props: {
    deleted: Boolean,
  },
  data() {
    return {
      activeBtn: '',
      params: {
        member: '',
        project: null,
        date: this.$moment().format('YYYY-MM-DD'),
      },
      pickedDate: null,
    };
  },
  computed: {
    ...mapGetters(['getFiltersData', 'getSelectedWorkspace']),
  },
  watch: {
    params: {
      handler(obj) {
        const { me, all } = this.getFiltersData.btns;

        if (me && all) {
          this.activeBtn = obj.member === this.getSelectedWorkspace.member_card_id
            ? 'me' : 'all';
        }

        this.setQueryParams(obj);
        this.$emit('handle-params', obj);
      },
      deep: true,
    },
  },
  created() {
    const findFirstTruthVal = Object.keys(this.getFiltersData.btns)
      .find((el) => this.getFiltersData.btns[el] === true);
    this.setActiveButton(findFirstTruthVal);

    if (Object.keys(this.$route.query).length > 0) {
      this.updateDataByQueryParams(this.$route.query);
    }
  },
  methods: {
    setActiveButton(value) {
      this.activeBtn = value;
      this.params.member = value === 'me'
        ? this.getFiltersData.members.find((el) => el.selected).member_card_id
        : this.params.member = this.getFiltersData.members[0].member_card_id;
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  .activity-bar {
    .bar-column {
      min-height: 127px;
    }
  }

  .deleted {
    display: flex;
    div {
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
</style>
