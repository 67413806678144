<template>
  <autocomplete
    v-model="model"
    class="member-autocomplete"
    label="Member"
    :items="items"
    item-text="name"
    item-value="member_card_id"
    :deletable-chips="false"
    :search-input.sync="searchValue"
    :small-chips="false"
    :hide-details="true"
    :icon-arrow="true"
    :menu-props="{
      maxWidth: 182,
      minWidth: 182
    }"
    @focus="searchValue = ''"
  />
</template>

<script>
import Autocomplete from '@/components/ui/Autocomplete/Autocomplete';

export default {
  name: 'MemberFilter',
  components: { Autocomplete },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    items: {
      type: Array,
      default: (() => []),
    },
  },
  data() {
    return {
      searchValue: '',
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
  .member-autocomplete {
    max-width: 180px;
    ::v-deep.v-input__slot {
      padding-left: 16px !important;

      fieldset {
        height: inherit !important;
      }
    }
  }
</style>
