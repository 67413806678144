<template>
  <custom-select
    v-model="sortBy[0]"
    arrow-class="black-icon"
    class="activity-level-select"
    item-text="name"
    item-value="name"
    :items="sortBy"
  />
</template>

<script>
import CustomSelect from '@/components/ui/CustomSelect/CustomSelect';

export default {
  name: 'SortBy',
  components: { CustomSelect },
  data() {
    return {
      sortBy: [
        {
          name: 'Projects',
        },
        {
          name: 'name',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>

  .activity-level-select {
    max-width: 140px !important;
  }

  ::v-deep .custom-select {
    max-height: 40px;

    .v-input__slot {
      min-height: 40px;
    }

    .v-input__control {
      height: 40px;
    }
  }
  ::v-deep .v-text-field .v-input__append-inner {
    margin-top: 13px;
  }

</style>
