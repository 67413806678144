<template>
  <autocomplete
    v-model="model"
    class="project-autocomplete"
    label="Project"
    item-text="name"
    item-value="id"
    :items="items"
    :deletable-chips="false"
    :small-chips="false"
    :hide-details="true"
    :icon-arrow="true"
    :project="true"
    :search-input.sync="searchValue"
    :menu-props="{
      maxWidth: 182,
      minWidth: 182
    }"
    @focus="searchValue = ''"
  />
</template>

<script>
import Autocomplete from '@/components/ui/Autocomplete/Autocomplete';

export default {
  name: 'ProjectFilter',
  components: { Autocomplete },
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    items: {
      type: Array,
      default: (() => []),
    },
  },
  data() {
    return {
      searchValue: '',
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep.project-autocomplete {
  max-width: 180px;

  .v-input__slot {
    padding-left: 16px !important;

    fieldset {
      height: inherit !important;
    }
  }
}

</style>
