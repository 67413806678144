<template>
  <v-row
    class="align-center activity-bar"
    style="min-height: 48px"
    no-gutters
  >
    <v-col
      class="d-flex align-center"
      :class="{'justify-space-between': canDeleteTime || addTime}"
      md="12"
    >
      <div
        v-if="sortBy"
        class="d-flex align-center mr-5"
      >
        <span class="mr-3">
          Sort by
        </span>
        <sort-by />
      </div>
      <v-row v-if="activityChart">
        <v-col md="2" class="d-flex flex-column py-0">
          <span class="big-info">
            {{ activityHours }}
          </span>
          <span class="activity-support-text">
            <v-icon x-small class="mr-1 dot-icon primary-dot">
              fiber_manual_record
            </v-icon>
            Hours worked
          </span>
        </v-col>
        <v-col md="2" class="d-flex flex-column py-0">
          <span class="avg-count big-info">
            {{ activityPercent }}
          </span>
          <span class="activity-support-text">
            <v-icon x-small class="mr-1 dot-icon success-dot">
              fiber_manual_record
            </v-icon>
            Avg activity
          </span>
        </v-col>
      </v-row>
      <activity-time-avg
        v-if="!activityChart"
        :activity-time="activityTime"
        :activity-percent="activityPercent"
      />
      <div class="d-flex align-center">
        <div
          v-if="addTime"
          class="ml-auto"
        >
          <text-button
            @click="$emit('add-manually-time')"
          >
            <v-icon class="primary-icon mr-2">
              add_circle
            </v-icon>
            {{ addTimeBtnText }}
          </text-button>
        </div>
        <text-button
          v-show="canDeleteTime"
          @click="$emit('delete-time')"
        >
          <v-icon class="primary-icon mr-2">
            delete
          </v-icon>
          Delete time
        </text-button>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import SortBy from '@/components/collections/ActivityBar/SortBy';
import TextButton from '@/components/ui/Buttons/TextButton';
import ActivityTimeAvg from '@/components/collections/ActivityBar/ActivityTimeAvg';

export default {
  name: 'ActivityBar',
  components: {
    ActivityTimeAvg,
    TextButton,
    SortBy,
  },
  props: {
    activityTime: {
      type: String,
      default: null,
    },
    activityHours: {
      type: String,
      default: null,
    },
    activityPercent: {
      type: [String, Number],
      default: null,
    },
    canDeleteTime: {
      type: Boolean,
      default: false,
    },
    addTimeBtnText: {
      type: String,
      default: 'Add time',
    },
    sortBy: Boolean,
    addTime: Boolean,
    activityChart: Boolean,
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  .activity-bar {
    .bar-column {
      min-height: 127px;
    }
  }

  .avg-info {
    margin-left: $spacing-8;
    .avg-count {
      color: $success;
    }
  }
  .big-info {
    font-size: $type-3xl;
    font-weight: $font-weight-medium;
  }
  .activity-support-text {
    font-size: $type-xs;
    color: $grey;
    display: flex;
    align-items: center;
  }
</style>
