<template>
  <div class="filter-buttons">
    <v-btn
      v-for="button in buttons"
      :key="button.id"
      :value="button.value"
      :disabled="!permissions[button.value]"
      class="custom-v__btn"
      :class="{'active-btn': button.value === activeBtn}"
      @click="setActiveBtn(button.value)"
    >
      {{ button.text }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'UserFilter',
  props: {
    activeBtn: {
      type: String,
      default: '',
    },
    permissions: {
      type: Object,
      default: (() => {}),
    },
  },
  data() {
    return {
      buttons: [
        { id: 1, value: 'me', text: 'Me' },
        { id: 2, value: 'all', text: 'All' },
      ],
    };
  },
  methods: {
    setActiveBtn(value) {
      if (this.activeBtn === value) return;
      this.$emit('set-active-button', value);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  .filter-buttons {
    background-color: $white;
    border-radius: $radius-3;
    border: 1px solid $grey-light;
    display: flex;
    align-items: center;
    padding: 4px;
    height: 48px;

    .custom-v__btn {
      min-width: 70px !important;
      height: 40px !important;
      background-color: $white;
      text-transform: none;
      border: unset;
      box-shadow: initial;

      &.active-btn {
        border: 2px solid $primary !important;
        background-color: $white !important;
        color: $primary;

        &:before {
          background-color: transparent;
          padding: 5px;
          border: unset !important;
        }
      }
    }
  }

  //::v-deep.v-btn-toggle.v-item-group.user-toggle-btn {
  //  max-width: 147px;
  //  background-color: $white;
  //  padding: 4px;
  //  height: 48px;
  //  border-radius: $radius-3;
  //  border: 1px solid $grey-light;
  //  display: flex;
  //  justify-content: center;
  //  align-items: center;
  //
  //  .v-btn.v-size--default.user-btn {
  //    min-width: 70px !important;
  //    height: 40px !important;
  //    background-color: $white;
  //    text-transform: none;
  //    border: unset;
  //  }
  //  .v-item--active.v-btn--active {
  //    border: 2px solid $primary !important;
  //    background-color: $white !important;
  //    color: $primary;
  //    &:before {
  //      background-color: transparent;
  //      padding: 5px;
  //      border: unset !important;
  //    }
  //  }
  //}
</style>
