<template>
  <div>
    <span class="date-picker-label">
      Pick a Date
    </span>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="v-btn-toggle v-item-group">
          <v-btn
            class="date-picker-btn"
            @click="prevDay('prev')"
          >
            <v-icon>
              keyboard_arrow_left
            </v-icon>
          </v-btn>
          <text-field
            v-model="formatDate"
            prepend-icon="calendar_today"
            readonly
            v-bind="{...$attrs, ...attrs}"
            @click:append="on.click"
            v-on="{...$on, ...on}"
          />
          <v-btn
            class="date-picker-btn disable"
            :disabled="formatDate === 'Today'"
            @click="nextDay"
          >
            <v-icon>
              keyboard_arrow_right
            </v-icon>
          </v-btn>
        </div>
      </template>
      <v-divider class="menu-divider" />
      <v-date-picker
        v-model="model"
        no-title
        color="primary"
        scrollable
        show-current
        :allowed-dates="allowedDays"
      />
    </v-menu>
  </div>
</template>

<script>
import TextField from '@/components/ui/TextField/TextField';

export default {
  name: 'DatePicker',
  components: { TextField },
  props: {
    value: {
      type: [Object, String],
      default: null,
    },
  },
  data() {
    return {
      date: null,
      menu: false,
      disableNextDay: false,
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.menu = false;
        this.$emit('input', value);
      },
    },
    formatDate() {
      const day = this.$moment(this.model).format('DD');
      const month = this.$moment(this.model).format('MMM');

      if (day === this.$moment().format('DD')) {
        return 'Today';
      }

      return `${day} ${month}`;
    },
  },
  methods: {
    allowedDays(val) {
      return val <= new Date().toISOString();
    },
    nextDay() {
      this.$emit('input', this.$moment(this.model).add(1, 'days')
        .format('YYYY-MM-DD'));
    },
    prevDay() {
      this.$emit('input', this.$moment(this.model).subtract(1, 'days')
        .format('YYYY-MM-DD'));
    },
  },
};
</script>

<style lang="scss" scoped>
  @import "@/sass/_variables.scss";

  .date-picker-label {
    font-size: 12px;
    display: block;
    margin-bottom: $spacing-2;
  }

  ::v-deep.v-btn-toggle.v-item-group {
    width: 207px;
    background-color: $white;
    padding: 4px;
    height: 48px;
    border-radius: $radius-3;
    border: 1px solid $grey-light;
    display: flex;
    justify-content: center;
    align-items: center;

    .v-btn.date-picker-btn {
      min-width: 20px !important;
      height: 44px !important;
      background-color: $white;
      text-transform: none;
      padding: 0 $spacing-2;
      border: unset;
      box-shadow: initial;
    }
    .v-item--active.v-btn--active {
      border: 2px solid transparent !important;
      background-color: $white !important;
      color: $white;
      &:before {
        background-color: transparent;
        padding: 5px;
        border: unset !important;
      }
    }
  }

  ::v-deep .v-input.v-text-field {
    margin-top: 0;
    border-left: 1px solid $grey-light;
    border-right: 1px solid $grey-light;
    padding: 0 15px;
    display: flex;
    align-items: center;
    .v-input__control {
      .v-input__slot {
        margin-bottom: 0;
        &:before {
          display: none;
        }
      }
      .v-text-field__details {
        display: none;
      }
    }
  }

  ::v-deep.v-menu__content {
    display: flex;
    flex-direction: column;
    padding-top: 6px;
    background-color: $white;
    width: 360px;
    .menu-divider {
      margin-top: $spacing-1;
      margin-bottom: $spacing-3;
    }
    .menu-buttons-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 20px;
      button {
        margin-right: 20px;
        max-width: 160px;
        height: 38px !important;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  ::v-deep .v-picker__body {
    width: 360px !important;
    tbody {

      td {
        .v-btn.v-btn--active.v-btn--rounded  {
          background-color: $primary !important;
          border-radius: $radius-3;
          .v-btn__content {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            text-align: center;
            flex: initial;
            color: $white;
            background-color: $primary;
            margin-left: 1px;
          }
          &:before {
            opacity: 0;
          }
        }
        .v-btn.v-date-picker-table__current.v-btn--rounded  {
          background-color: $primary !important;
          border-radius: 50%;
          .v-btn__content {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            text-align: center;
            flex: initial;
            color: $white;
            background-color: $primary;
            margin-left: 1px;
          }
          &.v-btn--active {
            border-radius: $radius-3;
            .v-btn__content {
              background-color: $white;
              color: $black;
            }
          }
        }
      }
    }
  }
  .v-btn.v-btn--disabled.v-btn--has-bg{
    background-color: rgba(0, 0, 0, 0) !important;
  }
</style>
